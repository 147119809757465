nav{
    background-color: var(--section);
    /* border-bottom: 1px solid var(--primary); */
    display: flex;
    justify-content: space-between;
    padding: 10px 5%;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
}
nav img{
    width: 55px;
    height: auto;
}
.menu{
    display: flex;
    column-gap: 50px;
    align-items: center;
}
.menu a{
    color: var(--heading);
    font-size: 14px;
    text-decoration: none;
}
.menu a:hover{
    color: var(--primary);
}
nav button{
    background-color: var(--primary);
    padding: 6px 20px;
    color: black;
    border: 0px;
    background-color: var(--primary);
}
.responsiveNav{
    display: none;
}
.menuIcon{
    display: none;
}
@media(max-width: 600px){
    nav .menu{
        display: none;
    }
    .responsiveNav{
        display: flex;
        flex-direction: column;
        background-color: var(--section);
        z-index: 20;
        right: 0px;
        width: 400px;
        position: fixed;
        top: 75px;
        padding: 30px 40px;
        border-left: 1px solid var(--primary);
        border-bottom: 1px solid var(--primary);
        row-gap: 20px;
    }
    .responsiveNav a{
        color: var(--heading);
        font-size: 14px;
        text-decoration: none;
    }
    .responsiveNav a:hover{
        color: var(--primary);
    }
    .responsiveNav button{
        background-color: var(--primary);
        padding: 8px 20px;
        color:black;
        border: 0px;
        background-color: var(--primary);
    }
    .menuIcon{
        display: flex;
        font-size: 25px;
    }
}
@media(max-width: 404px){
    .responsiveNav{
        width: 100%;
        border: 0px;
        padding: 40px 25px;
    }
}