.propertyCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}
.propertyCard .image {
  width: 100%;
  height: 250px;
  position: relative;
}
.propertyCard img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.propertyCard .image .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.652);
  display: none;
  top: 0px;
  align-items: center;
  justify-content: center;
}
.propertyCard .image .overlay svg {
  font-size: 30px;
}
.propertyCard:hover > .image .overlay {
  display: flex;
  transition: 500ms;
}
.cardContent {
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  padding: 10px;
}
.cardContent .head {
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cardContent h2 {
  font-size: 25px;
  color: var(--primary);
}
.cardContent h3 {
  font-size: 17px;
}
.cardContent p {
  font-size: 15px;
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.cardContent p span {
  font-weight: 600;
}
.propertyStat {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  font-size: 14px;
}
.propertyStatCard {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

@media (max-width: 650px) {
  .cardContent h3 {
    font-size: 15px;
  }
  .cardContent h2 {
    font-size: 20px;
  }
  .cardContent p {
    font-size: 13px;
  }
  .propertyStat {
    font-size: 13px;
  }
  .propertyCard .image {
    height: 220px;
  }
}
