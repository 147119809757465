footer{
    display: flex;
    justify-content: space-between;
    padding: 30px 5%;
    flex-wrap: wrap;
    row-gap: 10px;
}
footer p{
    font-size: 14px;
}
footer .contact{
    display: flex;
    column-gap: 20px;
}
footer .contact p{
    display: flex;
    align-items: center;
    column-gap: 6px;
}
footer .contact svg{
    font-size: 20px;
    color: var(--primary);
}
@media(max-width: 650px){
    footer p{
        font-size: 13px;
    }
    footer{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    footer .contact{
        flex-wrap: wrap;
        align-items: center;
    }
}