.hero {
  margin-top: 75px;
  height: 85vh;
  background-image: url("../images/etienne-beauregard-riverin-B0aCvAVSX8E-unsplash.jpg");
  background-size: cover;
  background-position: left;
}
.hero .overlay {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 5%;
  row-gap: 5px;
  background-color: rgba(0, 0, 0, 0.625);
  width: 100%;
  height: 100%;
}
.hero h1 {
  font-size: 40px;
  width: 550px;
  line-height: 48px;
}
.hero .desc {
  font-size: 15px;
  color: var(--text);
  margin-top: 5px;
  width: 580px;
}
.stats {
  margin-top: 10px;
  width: 580px;
}
.statWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 10px;
  row-gap: 10px;
}
.statCard {
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  width: fit-content !important;
  align-items: center;
}
/* .hero .statWrapper {
  display: flex;
  width: 100%;
  column-gap: 2%;
  row-gap: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}
.hero .statCard {
  width: 49% !important;
  background-color: #1a1a1ae5;
  padding: 20px;
  height: 100px !important;
  justify-content: center;
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  align-items: center;
} */

/* .hero .statCard:last-child {
  width: 100% !important;
} */
.statCard p {
  font-size: 13px !important;
  text-align: center;
  color: var(--text);
}
.statCard h2 {
  color: var(--primary);
  font-size: 30px;
}
.hero button {
  width: 150px;
  background-color: var(--primary);
  margin-top: 25px;
  border: 0px;
  padding: 10px 20px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero button:hover {
  border-radius: 25px;
  transition: 400ms;
  cursor: pointer;
}

.about {
  padding: 100px 5%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.about .heading {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.about h1 {
  font-size: 35px;
}
.about h1 span {
  color: var(--primary);
}
.about .heading p {
  font-size: 14px;
  line-height: 20px;
  color: var(--text);
}
.aboutContent {
  display: flex;
  justify-content: space-between;
}
.leftContent {
  margin-top: 10px;
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 2%;
}
.aboutstatCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 49%;
  padding: 20px 10px;
  background-color: #1a1a1a;
}
.aboutstatCard h2 {
  color: var(--primary);
}
.aboutstatCard p {
  font-size: 14px;
  text-align: center;
}
.rightContent {
  width: 46%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}
.rightContent p {
  display: flex;
  column-gap: 10px;
  font-size: 14px;
  color: var(--text);
}
.rightContent p span {
  width: 20px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--primary);
}
.properties {
  display: flex;
  flex-direction: column;
  padding: 20px 5%;
  align-items: center;
  row-gap: 30px;
}
.properties .heading {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
}
.properties .heading h1 {
  font-size: 35px;
}
.filterButtons {
  display: flex;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.filterButtons button {
  padding: 3px 10px;
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);
}
.filterButtons button:hover {
  border-radius: 25px;
  cursor: pointer;
  transition: 500ms;
}
.becomeRealtor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 5%;
  margin-top: 70px;
  row-gap: 20px;
  background-color: var(--section);
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-color: var(--primary);
  border-style: solid;
  text-align: center;
}
.becomeRealtor h1 {
  font-size: 35px;
}
.becomeRealtor button {
  padding: 10px 20px;
  background-color: var(--primary);
  border: 0px;
  color: black;
  font-size: 12px;
}
.becomeRealtor button:hover {
  border-radius: 25px;
  cursor: pointer;
  transition: 500ms;
}
.propertyCardWrapper {
  display: flex;
  column-gap: 2%;
  width: 100%;
  flex-wrap: wrap;
}
.propertyCardWrapper .card {
  width: 32%;
}
.arrowUp {
  position: fixed;
  top: 83vh;
  background-color: var(--primary);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  color: black;
  justify-content: center;
  border-radius: 100%;
  right: 5%;
}
.arrowUp:hover {
  cursor: pointer;
  opacity: 0.7;
}
@media (max-width: 1100px) {
  .aboutContent {
    flex-direction: column-reverse;
    row-gap: 10px;
  }
  .rightContent p span {
    height: 5px;
    display: none;
  }
  .about .heading {
    width: 100%;
  }
  .rightContent {
    width: 100%;
  }
  .leftContent {
    width: 100%;
    flex-direction: row;
  }
}
@media (max-width: 1000px) {
  .propertyCardWrapper {
    row-gap: 20px;
  }
  .propertyCardWrapper .card {
    width: 49%;
  }
}
@media (max-width: 650px) {
  .hero h1 {
    width: 500px;
    font-size: 35px;
    line-height: 40px;
  }
  .hero .desc {
    width: 500px;
    line-height: 20px;
  }
  .stats {
    width: 500px;
  }
  .statWrapper {
    width: 500px;
  }
  .about h1 {
    font-size: 25px;
  }
  .about .heading p {
    font-size: 13px;
  }
  .rightContent p {
    font-size: 13px;
  }
  .leftContent h2 {
    font-size: 25px;
  }
  .leftContent p {
    font-size: 12px;
  }
  .becomeRealtor {
    padding: 80px 5%;
  }
  .becomeRealtor h1 {
    font-size: 25px;
  }
  .becomeRealtor p {
    font-size: 13px;
  }
  .becomeRealtor button {
    padding: 8px 20px;
  }
  .properties .heading h1 {
    font-size: 25px;
  }
  .filterButtons button {
    font-size: 11px;
  }
}
@media (max-width: 600px) {
  .leftContent h2 {
    font-size: 23px;
  }
  .leftContent {
    height: fit-content;
    /* align-items: flex-start; */
  }
  .leftContent .statCard {
    width: 48%;
    align-items: flex-start;
  }
  .hero .statWrapper {
    display: flex;
    width: 100%;
    column-gap: 2%;
    row-gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  .hero .statCard {
    width: 49% !important;
    background-color: #1a1a1a;
    padding: 20px;
    height: 100px !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    align-items: center;
  }
  .hero .statCard:last-child {
    width: 100% !important;
  }
}
@media (max-width: 550px) {
  .hero h1 {
    width: 100%;
    font-size: 30px;
    line-height: 35px;
  }

  .properties .heading h1 {
    width: 100%;
    text-align: left;
  }
  .hero .desc {
    width: 100%;
    line-height: 20px;
    font-size: 14px;
  }
  .statWrapper {
    width: 100%;
  }
  .stats {
    width: 100%;
  }
  .statCard h2 {
    font-size: 25px;
  }

  .propertyCardWrapper .card {
    width: 100%;
  }
}
@media (max-width: 470px) {
  .hero {
    height: fit-content;
  }
  .hero .overlay {
    height: fit-content;
    padding: 50px 5%;
  }
  .hero h1 {
    font-size: 28px;
  }
  .hero .desc {
    font-size: 14px;
  }
  .statWrapper {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }
  .statWrapper .statCard {
    width: 100%;
    display: flex;
    align-items: flex-start;
    text-align: left;
  }
  .hero .statWrapper {
    flex-direction: row !important;
    justify-content: left;
    align-items: flex-start;
  }
  .hero .statWrapper .statCard {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .arrowUp {
    top: 90vh;
  }
}
@media (max-width: 400px) {
  .aboutstatCard h2 {
    font-size: 20px;
  }
}
