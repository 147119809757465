*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  transition: 200ms;
}
body{
  font-family: "Roboto", sans-serif;
  background-color: black;
  color: var(--text);
}
:root{
  --primary: #FFD210;
  --buttons: rgb(255, 210, 16);
  --background: black;
  --heading: white;
  --text: #d2d2d2;
  --section: #1A1A1A;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide{
  display: none;
}
