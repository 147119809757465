.heroVideo {
  position: relative;
}
.heroVideo iframe {
  margin-top: 75px;
  width: 100%;
  height: 70vh;
}
.heroVideo .overlay {
  background-color: black;
  position: absolute;
  top: 75px;
  left: 0px;
  right: 0px;
  /* height: 90vh; */
  width: 100%;
  height: 70vh;
  padding: 50px 5%;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.693) 15%,
    rgba(0, 0, 0, 0) 27%
  );
}
.heroVideo h1 {
  font-size: 30px;
}
.estateInfo {
  padding: 70px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  row-gap: 30px;
}
.estateInfo .topSection {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.estateInfo .duplexInfo {
  display: flex;
  flex-direction: column;
  row-gap: 0px;
  align-items: center;
}
.duplexInfo .tag {
  background-color: var(--primary);
  color: black;
  padding: 5px 10px;
}
.duplexInfo h2 {
  font-size: 25px;
}
.duplexInfo h1 {
  font-size: 45px;
  font-weight: 900;
  margin-top: -12px;
}
ul {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
li span {
  font-weight: 600;
}
.duplexInfo2 {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.duplexInfo2 .document {
  display: flex;
  column-gap: 10px;
}
.duplexInfo2 svg {
  font-size: 40px;
}
.duplexInfo2 .document span {
  font-size: 12px;
}
.duplexInfo2 .payment {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 20px;
  border: 1.5px solid var(--primary);
}
.duplexInfo2 .payment span {
  color: var(--primary);
  font-size: 13px;
  font-weight: 400;
}
.estateFeatures {
  font-size: 14px;
  text-align: center;
}
.estateFeatures span {
  color: var(--primary);
}
.buildings {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding: 100px 5%;
  padding-bottom: 0px;
}
.buildings h2 {
  font-size: 35px;
}
.buildingsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  align-items: center;
}
.buildingsWrapper .reverse {
  flex-direction: row-reverse;
}
.buildingsCard {
  display: flex;
  column-gap: 40px;
  align-items: flex-start;
}
.buildingsCard .image {
  width: 38%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.buildingsCard img {
  height: 400px;
  object-fit: cover;
  width: 100%;
}
.smallImages {
  display: flex;
  column-gap: 2%;
  width: 100%;
}
li {
  margin-left: 10px;
}
.smallImages img {
  height: 80px !important;
  width: 23.5%;
  border: 1px solid rgb(41, 41, 41);
}
.buildingsCard .content {
  width: 58%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.buildingsCard .content h2 {
  font-size: 30px;
}
.buildingsCard .content p {
  font-size: 15px;
  color: var(--text);
  row-gap: 20px;
  column-gap: 10px;
}
.location {
  display: flex;
  flex-direction: row !important;
  align-items: flex-start;
}
.location svg {
  width: 80px;
  height: auto;
}
.buildingsCard .content svg {
  font-size: 20px;
}
.buildingsCard .content .houseFeatures {
  display: flex;
  column-gap: 15px;
  flex-wrap: wrap;
  row-gap: 10px;
}
.buildingsCard .houseFeatures p {
  display: flex;
  align-items: center;
}
.buildingsCard .content h3 {
  font-size: 25px;
  display: flex;
  flex-wrap: wrap;
  color: var(--primary);
}
.contactProperty {
  background-color: var(--section);
  padding: 100px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  text-align: center;
  margin-top: 100px;
}
.contactProperty h2 {
  font-size: 30px;
  font-weight: 600;
}
.contactProperty p {
  font-size: 18px;
}
.contactProperty a {
  color: var(--primary);
  text-decoration: none;
}
.contactProperty a:hover {
  text-decoration: underline;
}

@media (max-width: 1400px) {
  .buildings {
    padding: 0px 5%;
  }
}
@media (max-width: 1100px) {
  .buildingsCard img {
    height: 250px;
  }
  .buildingsCard .smallImages img {
    height: 70px;
  }
  .buildingsCard .content h2 {
    font-size: 25px;
  }
  .buildingsCard .content h3 {
    font-size: 22px;
  }
  .buildingsCard .content p {
    font-size: 13px;
  }
}
@media (max-width: 650px) {
  .heroVideo h1 {
    font-size: 25px;
  }
  .buildingsWrapper {
    row-gap: 40px;
  }
  .buildingsCard {
    flex-direction: column;
    row-gap: 20px;
  }
  .buildingsCard .image {
    width: 100%;
  }
  .buildingsCard .content {
    width: 100%;
  }
  .buildingsWrapper .reverse {
    flex-direction: column;
  }
  .buildings h2 {
    font-size: 25px;
  }
  .contactProperty h2 {
    font-size: 25px;
    font-weight: 600;
  }
  .contactProperty p {
    font-size: 15px;
  }
}
@media (max-width: 550px) {
  .estateInfo .topSection {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
  .duplexInfo2 {
    align-items: center;
  }
}
@media (max-width: 450px) {
  .buildingsCard img {
    height: 200px;
  }
  .buildingsCard .smallImages img {
    height: 60px;
  }
  .buildingsCard .content h2 {
    font-size: 22px;
  }
  .buildingsCard .content h3 {
    font-size: 18px;
  }
  .buildingsCard .content p {
    font-size: 13px;
  }
  .contactProperty h2 {
    font-size: 22px;
    font-weight: 600;
  }
  .contactProperty p {
    font-size: 14px;
  }
}
@media (max-width: 350px) {
  .buildingsCard img {
    height: 180px;
  }
  .buildingsCard .smallImages img {
    height: 50px;
  }
  .buildingsCard .content h2 {
    font-size: 21px;
  }
  .buildingsCard .content h3 {
    font-size: 19px;
  }
  .contactProperty h2 {
    font-size: 18px;
    font-weight: 600;
  }
  .contactProperty p {
    font-size: 13px;
  }
}
